/* Improve accessibility by enabling tabindexed based navigation */
document.addEventListener('keypress', (event) => {
  if (event.which === 13 && document.activeElement) {
    const element = document.getElementById(document.activeElement.getAttribute('for'));
    if (element && element.type === 'checkbox') {
      element.checked = !element.checked;
    } else if (element && element.type === 'radio') {
      element.checked = true;
    }
  }
});
