const videoBoxSections = Array.from(document.querySelectorAll('.video-box'));

videoBoxSections.forEach((videoBox) => {
  const input = videoBox.querySelector('.video-box__video-player-active');
  const iframe = videoBox.querySelector('iframe');

  input.addEventListener('change', () => {
    if (input.checked) {
      iframe.src = iframe.dataset.src;
    } else {
      iframe.removeAttribute('src');
    }
  });
});
