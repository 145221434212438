/*
 * Require all scripts here and they will be bundled by Rollup
 * - Wrapping the scripts in an IIFE to create a local scope is not neccessary
 * - Including 'use strict' in the scripts is not neccessary and should be left out
 * - ES2015 sytax is available and will be compiled to ES5 for compatibility
 */
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

require('@babel/polyfill');
require('./a11y');
require('./lightbox');

// Presets
require('./default-nav');
require('./video-box');
require('./variants');
require('./scroll');
require('./slider');
