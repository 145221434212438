/* eslint no-param-reassign: "off" */
/* eslint no-return-assign: "off" */

// require('scroll-lock/scroll-lock');

let searchBox;
let searchBoxToggle;
let wpMenu;
let searchInput;
let searchLabel;
let mobileMenuToggle;
let scrollPos = 0;
let navDesktop;
let navMobile;

let isNavHovered = false;

const topThreshold = 400;
const slideOutThreshold = 50;

function closeSearchBox(event) {
  searchBoxToggle.checked = false;
  wpMenu.classList.remove('disablehover');
  document.removeEventListener('click', closeSearchBox);
  if (event.target.parentNode === searchLabel) {
    event.preventDefault();
  }
}

function slideOut() {
  if (isNavHovered || mobileMenuToggle.checked) {
    return;
  }
  const newScrollPos = window.pageYOffset;
  const delta = newScrollPos - scrollPos;

  if (newScrollPos < topThreshold) {
    navDesktop.classList.remove('default-nav-desktop--hide');
    navMobile.classList.remove('default-nav-mobile--hide');
    return;
  }

  if (newScrollPos > window.innerHeight) {
    navMobile.classList.add('default-nav-mobile--out-of-threshold');
  } else {
    navMobile.classList.remove('default-nav-mobile--out-of-threshold');
  }

  if (Math.abs(delta) < slideOutThreshold) {
    return;
  }

  scrollPos = newScrollPos;

  if (delta > 0) {
    navDesktop.classList.add('default-nav-desktop--hide');
    navMobile.classList.add('default-nav-mobile--hide');
  } else {
    navDesktop.classList.remove('default-nav-desktop--hide');
    navMobile.classList.remove('default-nav-mobile--hide');
  }
}

function setup() {
  document.addEventListener('touchstart', () => {}, true);
  searchBox = document.querySelector('.default-nav__search');
  searchBoxToggle = document.querySelector('.default-nav__search-toggle');
  wpMenu = document.querySelector('.default-nav__wp-menu');
  searchInput = document.querySelector('.default-nav__search-input');
  searchLabel = document.querySelector('.default-nav__search-label');

  mobileMenuToggle = document.querySelector('.default-nav-mobile__mobile');

  navDesktop = document.querySelector('.default-nav-desktop');
  navMobile = document.querySelector('.default-nav-mobile');

  if (searchBox) {
    searchLabel.addEventListener('click', () => {
      if (!searchBoxToggle.checked) {
        wpMenu.classList.add('disablehover');
        setTimeout(() => document.addEventListener('click', closeSearchBox), 0);
      }
    });
    searchBox.addEventListener('click', event => event.stopPropagation());
    searchInput.addEventListener('focus', () => {
      window.setTimeout(() => wpMenu.scrollTop = wpMenu.scrollHeight, 200);
    });
  }

  navDesktop.addEventListener('mouseenter', () => {
    isNavHovered = true;
  });
  navDesktop.addEventListener('mouseleave', () => {
    isNavHovered = false;
  });

  document.addEventListener('scroll', slideOut);
}

window.addEventListener('DOMContentLoaded', setup);
