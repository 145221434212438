const sections = Array.from(document.querySelectorAll('.variants'));

sections.forEach((section) => {
  const inputs = Array.from(section.querySelectorAll('.variants-slider__input'));
  const controls = Array.from(section.querySelectorAll('.variants-slider__index-control'));
  const dots = Array.from(section.querySelectorAll('.variants-slider__index-dots'));
  const maxIndex = inputs.length - 1;

  if (maxIndex > 4) {
    inputs.forEach((input, index) => {
      input.addEventListener('change', () => {
        const activeIndex = index;

        const showControls = Array(maxIndex + 1).fill(false);
        showControls[0] = true; // Always show the first item
        showControls[maxIndex] = true; // Always show the last item

        const showDots = Array(maxIndex + 1).fill(false);

        if (activeIndex === 0) { // Show the first three items and the last item
          showControls[1] = true;
          showControls[2] = true;
          showDots[2] = true;
        } else if (activeIndex === maxIndex) { // Show the first item and the last three items
          showControls[maxIndex - 2] = true;
          showControls[maxIndex - 1] = true;
          showDots[maxIndex - 3] = true;
        } else { // Show the first & last item, the active item and the ones next to it
          showControls[activeIndex - 1] = true;
          showControls[activeIndex] = true;
          showControls[activeIndex + 1] = true;
          if (activeIndex > 2 && activeIndex < maxIndex) {
            showDots[activeIndex - 2] = true;
          }
          if (activeIndex < maxIndex - 2 && activeIndex > 0) {
            showDots[activeIndex + 1] = true;
          }
        }

        showControls.forEach((showControl, i) => {
          if (showControl) {
            controls[i].classList.remove('variants-slider__index-control--hidden');
          } else {
            controls[i].classList.add('variants-slider__index-control--hidden');
          }
        });

        showDots.forEach((showDot, i) => {
          if (showDot) {
            dots[i].classList.remove('variants-slider__index-dots--hidden');
          } else {
            dots[i].classList.add('variants-slider__index-dots--hidden');
          }
        });
      });
    });
  }
});
