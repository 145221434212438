window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll("a[href^='#']").forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      const section = document.getElementById(item.getAttribute('href').replace('#', ''));

      if (!section) {
        return;
      }

      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  });
});
